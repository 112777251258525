import colors from './colors.json';

export default {
  "general-h1": `
    font-family: Circular;
    font-weight: 450;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: 1%;
    text-transform: uppercase;
  `,
  "general-h2": `
    font-family: Circular;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
  "general-h3": `
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.5px;
    text-align: left;
  `,
  "general-body1": `
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  `,
  "general-body2": `
    font-family: Circular;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.6px;
  `,
  "general-button1": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
    text-transform: uppercase;
  `,
  "general-button2": `
    font-family: Circular;
    font-size: 14px;
    font-weight: lighter;
  `,
  "general-link": `
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
  `,
  "general-subtitle1": `
    font-family: Circular;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "general-subtitle2": `
    font-family: Circular;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "detail-h1": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
    line-height: 0.14px;
    text-transform: uppercase;
  `,
  "detail-description": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.textSecondary};
  `,
  "detail-body1": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 400;
  `,
  "detail-body2": `
    font-family: Circular;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "detail-subtitle1": `
    font-family: Circular;
    font-weight: 450;
    font-size: 13px;
  `,
  "detail-subtitle2": `
    font-family: Circular;
    font-weight: 450;
    font-size: 13px;
  `,
  "detail-name": `
    font-family: Circular;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  `,
  "detail-brand": `
    font-family: Circular;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  `,
  "detail-price": `
    font-family: Circular;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  `,
  "detail-ogprice": `
    font-family: Circular;
    font-weight: 300;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
    text-decoration: line-through;
  `,
  "detail-link": ``,
  "detail-condition": ``,
  "nav-body1": `
    font-family: Circular;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: -0.5px;
  `,
  "nav-subtitle1": `
    font-family: Circular;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: -0.5px;
  `,
  "nav-button1": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
    line-height: 0.14px;
    letter-spacing: 1%;
    text-transform: uppercase;
  `,
  "nav-button2": `
    font-family: Circular;
    font-size: 14px;
    font-weight: lighter;
  `,
  "nav-search": `
    font-family: Circular;
    font-size: 14px;
    font-weight: 400;
  `,
  Body2: `
    font-family: Circular;
    font-size: 14px;
  `,
  "listing-price1": `
    font-family: Circular;
    font-weight: 500;
    font-size: 13px;
    line-height: 15.6px;
    text-transform: uppercase;
  `,
  "listing-price2": `
    font-family: Circular;
    font-weight: 500;
    font-size: 11px;
    line-height: 15.6px;
    text-decoration: line-through;
    text-transform: uppercase;
  `,
  "listing-title": `
    font-family: Circular;
    font-weight: 500;
    font-size: 13px;
    line-height: 15.6px;
    letter-spacing: 1%;
    text-transform: uppercase;
  `,
  "listing-subtitle": `
    font-family: Circular;
    font-size: 11px;
    font-weight: 400;
    color: ${colors.textSecondary};
  `,
  "listing-condition": ``,
  "drawer-title": `
    font-family: Circular;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
  `,
};
